import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export { User };

@Injectable()
export class AuthService {

  private _userManager: UserManager;
  _user: User;

  _loginChangedSubject = new Subject<boolean>();

  loginChanged = this._loginChangedSubject.asObservable();
  //authContext: AuthContext;

  constructor() {
    const settings = {
      authority: environment.stsAuthority,
      client_id: environment.clientId,
      redirect_uri: `${window.location.origin}/rate`,
      silent_redirect_uri: `${window.location.origin}/rate/assets/silent-callback.html`,
      post_logout_redirect_uri: `${window.location.origin}/rate/signout-callback`,
      response_type: 'id_token',
      scope: environment.clientScope,
      automaticSilentRenew: true
    };

    this._userManager = new UserManager(settings);
    this._userManager.events.addAccessTokenExpired(_ => {
      this._loginChangedSubject.next(false);
    });

    this._userManager.events.addUserLoaded(user => {
      if (this._user !== user) {
        this._user = user;
        this._loginChangedSubject.next(!!user);
      }
    });
  }

  getUser() {
    return this._userManager.getUser();
  }


  login() {
    return this._userManager.signinRedirect();
  }

  isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then(user => {
      if (!!user) {
        const userCurrent = !!user //&& user.profile.exp > Date.now();
        if (this._user !== user) {
          this._loginChangedSubject.next(userCurrent);
        }
        this._user = user;
        return userCurrent;
      } else {
        return false;
      }
    });
  }

  completeLogin() {
    this._userManager.startSilentRenew()
    return this._userManager.signinRedirectCallback().then(user => {
      this._user = user;
      this._loginChangedSubject.next(!!user && user.profile.exp > Date.now());
      return user;
    });
  }

  checkLoggedIn() {
    return this._userManager.signinSilentCallback().then(usr => {
      this._user = usr;
      this._loginChangedSubject.next(!!usr && usr.profile.exp > Date.now());
      return usr;
    })
  }

  logout() {
    this._userManager.signoutRedirect();
  }

  completeLogout() {
    this._userManager.stopSilentRenew()
    this._user = null;
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  getAccessToken() {
    return this._userManager.getUser().then(user => {
      return user.access_token;
    });
  }

  getIdToken() {
    return this._userManager.getUser().then(user => {
      return user.id_token;
    });
  }

}
