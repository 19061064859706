<div class="alert-container" [ngClass]="{'alert-primary': alertInformation.type === alertType.INFO, 'alert-success' : alertInformation.type === alertType.SUCCESS, 'alert-danger' : alertInformation.type === alertType.DANGER, 'alert-warning' : alertInformation.type === alertType.WARNING}" *ngFor="let alertInformation of alertInformations" >
  <div class="alert-content">
    <div class="alert-detail">
      <label class="alert-title">{{alertInformation.title}}</label>
      <label class="alert-description">{{alertInformation.description}}</label>
    </div>
    <span class="spacer"></span>
    <div calss="alert-action">
      <div class="basic-container">
        <mat-progress-spinner
          [diameter]="30"
          [mode]="alertInformation.mode"
          [value]="alertInformation.value">
        </mat-progress-spinner>
        <mat-icon (click)="alertInformation.close()" class="material-icons">close</mat-icon>
      </div>

    </div>
  </div>

</div>
