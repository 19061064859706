import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { catchError, Observable, of, retry, tap, throwError } from "rxjs"
import { DialogContentComponent } from "../dialog-content/dialog-content.component"

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== "GET") {
      this.cache.clear()
      return next.handle(req)
    }
    const cachedResponse: HttpResponse<any> | undefined = this.cache.get(req.url)
    if (cachedResponse && !req.url.includes("api/v2")) {
      return of(cachedResponse.clone())
    } else {
      this.cache.set(req.url, new HttpResponse());
      return next.handle(req).pipe(
        tap(stateEvent => {
          if (stateEvent instanceof HttpResponse ) {
            this.cache.set(req.url, stateEvent.clone())
          }
        })
      )
    }
  }





  private cache: Map<String, HttpResponse<any>> = new Map()



}
