import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, from, lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private _authService: AuthService,
    private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('')) {
      return from(this._authService.getIdToken().then((token) => {

        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`).set('Access-Control-Allow-Origin', '*');
        const reqAuthorized = req.clone({headers});
        return lastValueFrom(next.handle(reqAuthorized));
      }))
    }
    else {
      return next.handle(req);
    }
  }





}
