<!-- <app-top-bar (onClickLogin)="login()" (onClickLogout)="logout()" *ngIf="isLoggedIn"></app-top-bar>
bottoni per il check login da eliminare poi
<button (click)="login()">LOGIN</button>
<button (click)="logout()">LOGOUT</button>
<button (click)="check()">CHECK</button>
<div class="scrollable-spacer"><router-outlet ></router-outlet></div>-->

<div class="container">
  <div class="header">
    <app-top-bar (onClickLogin)="login()" (onClickLogout)="logout()" *ngIf="isLoggedIn"></app-top-bar>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>

</div>
