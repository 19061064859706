import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TopBarComponent } from './rate/topbar/top-bar.component';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { CacheInterceptor } from './services/cache-interceptor'
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { FooterComponent } from './rate/footer/footer.component';

import { AuthService } from './services/auth.service';
import { AdminRouteGuard } from './auth/admin-route-guard';
import { AuthInterceptorService } from './services/auth.interceptor.service';
import { Logger } from './services/logger.service';
import { BuilderJSON } from './services/builderJSON.service';
import { DialogComponent } from './dialog/dialog.component';
import { ResizableDirective } from './resizable.directive';


const routes: Routes = [
  { path: '', loadChildren: () => import('./rate/rate.module').then(m => m.RateModule), canActivate: [AdminRouteGuard] },
]

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    DialogContentComponent,
    DialogComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    SharedModule
  ],
  providers: [
    Logger,
    AuthService,
    AdminRouteGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    BuilderJSON

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
