import { Injectable, isDevMode } from "@angular/core";

export enum LoggerType {
  INFO,
  VERBOSE,
  WARNING,
  DEBUG,
  ERROR,
  LOG
}


@Injectable()
export class Logger {
  log(message:any, type?:LoggerType) {
    if (isDevMode()) {
      switch (type) {
        case LoggerType.INFO:
          console.info(message)
          break;
        case LoggerType.VERBOSE || LoggerType.LOG:
          console.log(message)
          break;
        case LoggerType.WARNING:
          console.warn(message)
          break;
        case LoggerType.DEBUG:
          console.debug(message)
          break;
        case LoggerType.ERROR:
          console.error(message)
          break;
        default:
          break;
      }
    }
  }
}
