// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  /*
  stsAuthority: 'https://login.microsoftonline.com/d2007bef-127d-4591-97ac-10d72fe28031/v2.0',
  clientId: '4a9880d7-54f0-4ed3-94b6-21173defaf5e',
  clientRoot: window.location.hostname,
  clientScope: 'User.Read openid profile email offline_access',
  apiRoot: 'api://4a9880d7-54f0-4ed3-94b6-21173defaf5e',
  apiUrl: '/rate/api/v1/',
  authUrl: '/auth/api/v1/',
  */

  stsAuthority: window["env"].stsAuthority || " ",
  clientId: window["env"].clientId || " ",
  clientRoot: window.location.hostname,
  clientScope:  window["env"].clientScope || " ",
  apiRoot:  window["env"].apiRoot || " ",
  apiUrl:  window["env"].apiUrl || " ",
  authUrl:  window["env"].authUrl || " ",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
