import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DnDComponent } from '../dnd/dnd.component';
import { DndDirective } from '../dnd/dnd.directive';
import { MaterialModule } from './material.module';
import { AlertComponent } from '../alert/alert.component';
import { ResizableDirective } from '../resizable.directive';



@NgModule({
  declarations: [
    DnDComponent,
    DndDirective,
    AlertComponent,
    ResizableDirective
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    DnDComponent,
    DndDirective,
    AlertComponent,
    ResizableDirective
  ]
})
export class SharedModule { }
