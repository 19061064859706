import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild, isDevMode } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { catchError, EMPTY } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor(private http:HttpClient, private _authService : AuthService) { }

  @ViewChild('account') public account:MatMenu;

  @Output() onClickLogin: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogout: EventEmitter<any> = new EventEmitter();

  login(){
    this.onClickLogin.emit();
  }

  logout(){
    this.onClickLogout.emit();
  }

  isDevMode(){
    return isDevMode();
  }



  public username:string | null;
  public email:string | null;

  public errorHandler = (error: any) => {
    console.log(error)
    return EMPTY;
  }

  ngOnInit(): void {
    /*const account = this.authService.instance.getActiveAccount();
    const accessTokenRequest = {
      scopes: ["User.Read", "openId", "email", "profile", "offline_access"],
      account: account,
   }*/
   this._authService.getUser().then(user => {
     if(!!user){
       this.username = user.profile.name;
       this.email = user.profile.email;
     }
   })
  }




}
