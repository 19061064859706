import { Injectable } from "@angular/core";
import { Rate } from "../rate/models";

@Injectable()
export class BuilderJSON {
  constructor() { };

  makeOffer(params: {
    rateDistanceGid?: String,
    rateServiceGid?: String,
    equipmentGroupProfileGid?: String,
    transportModeGid?: String,
    servprovGid?: String,
    rateOfferingXid?: String,
    rtRateOfferingId?: number,
    rtStato?: String,
    rtValidoDa?: Date,
    rtValidoA?: Date,
    template?: String
    currencyGid?: String,
    rateOfferingTypeGid?: String
  }): Rate {
    const offer: Rate = {
      ...((!!params.rtRateOfferingId) && { rtRateOfferingId: params.rtRateOfferingId }),
      rateDistanceGid: params.rateDistanceGid,
      rateServiceGid: params.rateServiceGid,
      equipmentGroupProfileGid: params.equipmentGroupProfileGid,
      transportModeGid: params.transportModeGid,
      servprovGid: params.servprovGid,
      rateOfferingXid: params.rateOfferingXid,
      rtStato: params.rtStato,
      rtValidoDa: params.rtValidoDa,
      rtValidoA: params.rtValidoA,
      currencyGid: params.currencyGid,
      template: params.template,
    }
    return offer;
  }
}
