import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Logger, LoggerType } from './services/logger.service';
//import { OAuthService } from 'angular-oauth2-oidc';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'RatesToolFrontEnd';
  isIframe = false;
  isLoggedIn = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(
    private router: Router, public _authService: AuthService, private _logger: Logger
  ) {

    this._authService.loginChanged.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    })

  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;


    this._authService.completeLogin().then(user => {
      this.isLoggedIn = true;
      this.router.navigate(['/'], { replaceUrl: true });
    }).catch(err => { this._logger.log(err, LoggerType.ERROR); this._authService.login().then(() => { this._authService.completeLogin()}) })

    //this._authService.checkLoggedIn().then(user => this.router.navigate(['/'], { replaceUrl: true }))
  }


  login() {
    this._authService.login()
  }

  logout() {
    this._authService.logout()
  }

  check(){

    this._authService.getUser().then(user => console.log(user))
    /*this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken }) => {

      console.log('app authenticated', isAuthenticated);
      console.log(`Current access token is '${accessToken}'`);
      console.log(userData);
    });*/
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
